import { defineStore } from 'pinia'
import type {
    FinanceApplication,
    FinanceApplicationSettings,
} from '~/utils/types/financeApplication'
import { useFetchDealerMe } from '~/composables/fetch/useFetchDealerMe'
import { useFetchAutoznetwork } from '~/composables/fetch/useFetchAutoznetwork'
import type { Vehicle } from '~/utils/types/inventory'

export const useFinanceApplicationStore = defineStore('financeApplication', {
    state: (): State => ({
        application: null,
        settings: null,
        vehicle: null,
        testData: [
            {
                first_name: 'Test',
                last_name: 'User',
                email: 'test@user.com',
                phone_number: '(425) 645-5423',
                terms: true,
            },
            {
                social_security_number: '111-11-1111',
                date_of_birth: '05/15/1990',
                license: {
                    number: '12345LICENSE',
                    state: 'WA',
                    expiration: '10/26',
                },
                vehicle_of_interest: '#13221',
                down_payment: 5000,
                employment_status: 'employed',
                employment: [
                    {
                        company: 'Dunder Muffins',
                        job_title: 'Assistant to the Regional Manager',
                        income: 15000,
                        income_measurement: 'year',
                        time_on_job: 3,
                        time_on_job_measurement: 'years',
                        contact_number: '(425) 391-5491',
                        street_address: '1234 Business St',
                        city: 'Seattle',
                        state: 'WA',
                        postal_code: '98199',
                    },
                ],
                housing_status: 'own',
                housing_payment: 3000,
                housing: [
                    {
                        time_at_residence: 3,
                        time_at_residence_measurement: 'years',
                        street_address: '1234 Home St',
                        city: 'Seattle',
                        state: 'WA',
                        postal_code: '98199',
                    },
                ],
                optional_fields: 'no',
            },
            {
                cosigner_added: 0,
            },
            {
                terms: true,
            },
        ],
    }),
    actions: {
        async getFinanceApplicationSettings() {
            const { locale } = useI18n()

            const { data: settings } = await useFetchDealerMe(
                '/finance-applications/settings',
                { query: { locale: locale.value } },
            )

            this.settings = settings.value
        },

        async getVehicle(id: number) {
            const { data: vehicle } = await useFetchAutoznetwork(
                `/inventory/${id}`,
            )

            this.vehicle = vehicle.value
        },

        async getCosigner(id: number, email: string) {
            const { data: res } = await useFetchDealerMe(
                '/finance-applications/cosigner',
                {
                    query: {
                        cosigning_for: id,
                        email,
                    },
                },
            )

            return res.value
        },

        async createFinanceApplication(body: Record<string, any>) {
            return await useFetchDealerMe('/finance-applications', {
                method: 'post',
                body,
                fetch: true,
            })
        },

        async updateFinanceApplication(
            financeApplication: FinanceApplication,
            body: Record<string, any>,
        ) {
            return await useFetchDealerMe(
                `/finance-applications/${financeApplication.id}`,
                {
                    method: 'put',
                    body,
                    fetch: true,
                },
            )
        },

        reset() {
            this.application = null
            this.vehicle = null
        },

        resetSettings() {
            this.settings = null
        },
    },
})

interface State {
    application: FinanceApplication | null
    settings: FinanceApplicationSettings | null
    vehicle: Vehicle | null
    testData: Record<string, any>[]
}
