import { defineStore } from 'pinia'
import type { Deposit, DepositMeta } from '~/utils/types/deposit'

export const useDepositStore = defineStore('deposit', {
    state: (): State => ({
        deposit: null,
        meta: null,
        podiumUrl: null,
        testData: [
            {
                first_name: 'Test',
                last_name: 'User',
                email: 'test@user.com',
                phone_number: '(425) 645-5423',
            },
            {},
            {},
        ],
    }),
    actions: {
        updateDeposit(
            data: Record<string, any>,
            meta: DepositMeta | null = null,
        ) {
            this.deposit = {
                ...(this.deposit ?? {}),
                ...data,
            }

            if (meta !== null) {
                this.meta = meta
            }
        },

        reset() {
            this.deposit = null
            this.meta = null
            this.podiumUrl = null
        },
    },
})

interface State {
    deposit: Deposit | null
    meta: DepositMeta | null
    podiumUrl: string | null
    testData: Record<string, any>[]
}
