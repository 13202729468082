export enum Department {
    SALES = 'sales',
    SERVICE = 'service',
    PARTS = 'parts',
    DETAIL = 'detail',
}

export enum HourStatus {
    OPEN = 'open',
    CLOSED = 'closed',
    PRE_OPEN = 'preOpen',
}

export enum DayOfTheWeek {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday',
}
