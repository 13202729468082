import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordion, LazyAccordionBlok, LazyCenteredAccordion, LazyBlogArticles1, LazyBodyStyleBar, LazyCallToAction, LazyCallToActionSimpleCentered, LazyCallToActionSimpleJustified, LazyCallToActionSplitPanel, LazyCallToActionBackgroundImage, LazyCallToActionImage, LazyCallToActionOverlap, Lazy3ColumnCollection, LazyContentWithImageTiles, LazyCountdown, LazyElfsightApplication, LazyFeature1, LazyFeature2, LazyFlexPortraitVideo, LazyFlexVideo, LazyFullWidthBlok, LazyGrid, LazyGridImage, LazyGridRichText, LazyGridx4, Lazy3ColumnIncentivesPanel, LazyInventoryBar, LazyLargeVideo, LazyLogoCloud, LazyLogoCloudItem, LazyMakeSlider, LazyProductFeaturesWithAlternatingSections, LazyProductFeaturesWithSquareImages, LazyPromoSectionFullWidthWithBackgroundImage, LazyPromoSectionFullWidthWithOverlappingImageTiles, LazyPromoSectionLargeWithBackgroundImage, LazyPromoSectionWithBackgroundImage, LazyPromoSectionWithFadingBackgroundImage, LazyPromoSectionWithOverlappingImageTiles, LazyRecentlyViewed, LazyRichText, LazySalespersonOfTheMonth, LazySearchBar, LazySpacer, LazyTestimonialWithBackgroundImage, LazyTestimonialWithLargeAvatar, LazyTestimonialWithOverlappingImage, LazyTestimonials1, LazyVideo1, LazyYoutubeVideo, LazyFullwidthhero1, LazyHero1, LazyHero2, LazyTabColumns, LazyTabImageGrid, LazyTabLink, LazyTabSimple, LazyPage, LazySellHeader, LazyCapitalOneCalculator, LazyCapitalOneLeadsNavigator, LazyCapitalOnePrequalification, LazyCapitalOneShowroom, LazyEdmundsTradeIn, LazyEdmundsTradeInWidget, LazyEdmundsTradeInWidgetSlim, LazyIpacketButton, LazySvgoFacebook, LazySvgoFeaturesAndroidAuto, LazySvgoFeaturesAppleCarplay, LazySvgoInstagram, LazySvgoLeaf, LazySvgoPlug, LazySvgoX, LazySvgoYoutube } from '#components'
const lazyGlobalComponents = [
  ["Accordion", LazyAccordion],
["AccordionBlok", LazyAccordionBlok],
["CenteredAccordion", LazyCenteredAccordion],
["BlogArticles1", LazyBlogArticles1],
["BodyStyleBar", LazyBodyStyleBar],
["CallToAction", LazyCallToAction],
["CallToActionSimpleCentered", LazyCallToActionSimpleCentered],
["CallToActionSimpleJustified", LazyCallToActionSimpleJustified],
["CallToActionSplitPanel", LazyCallToActionSplitPanel],
["CallToActionBackgroundImage", LazyCallToActionBackgroundImage],
["CallToActionImage", LazyCallToActionImage],
["CallToActionOverlap", LazyCallToActionOverlap],
["3ColumnCollection", Lazy3ColumnCollection],
["ContentWithImageTiles", LazyContentWithImageTiles],
["Countdown", LazyCountdown],
["ElfsightApplication", LazyElfsightApplication],
["Feature1", LazyFeature1],
["Feature2", LazyFeature2],
["FlexPortraitVideo", LazyFlexPortraitVideo],
["FlexVideo", LazyFlexVideo],
["FullWidthBlok", LazyFullWidthBlok],
["Grid", LazyGrid],
["GridImage", LazyGridImage],
["GridRichText", LazyGridRichText],
["Gridx4", LazyGridx4],
["3ColumnIncentivesPanel", Lazy3ColumnIncentivesPanel],
["InventoryBar", LazyInventoryBar],
["LargeVideo", LazyLargeVideo],
["LogoCloud", LazyLogoCloud],
["LogoCloudItem", LazyLogoCloudItem],
["MakeSlider", LazyMakeSlider],
["ProductFeaturesWithAlternatingSections", LazyProductFeaturesWithAlternatingSections],
["ProductFeaturesWithSquareImages", LazyProductFeaturesWithSquareImages],
["PromoSectionFullWidthWithBackgroundImage", LazyPromoSectionFullWidthWithBackgroundImage],
["PromoSectionFullWidthWithOverlappingImageTiles", LazyPromoSectionFullWidthWithOverlappingImageTiles],
["PromoSectionLargeWithBackgroundImage", LazyPromoSectionLargeWithBackgroundImage],
["PromoSectionWithBackgroundImage", LazyPromoSectionWithBackgroundImage],
["PromoSectionWithFadingBackgroundImage", LazyPromoSectionWithFadingBackgroundImage],
["PromoSectionWithOverlappingImageTiles", LazyPromoSectionWithOverlappingImageTiles],
["RecentlyViewed", LazyRecentlyViewed],
["RichText", LazyRichText],
["SalespersonOfTheMonth", LazySalespersonOfTheMonth],
["SearchBar", LazySearchBar],
["Spacer", LazySpacer],
["TestimonialWithBackgroundImage", LazyTestimonialWithBackgroundImage],
["TestimonialWithLargeAvatar", LazyTestimonialWithLargeAvatar],
["TestimonialWithOverlappingImage", LazyTestimonialWithOverlappingImage],
["Testimonials1", LazyTestimonials1],
["Video1", LazyVideo1],
["YoutubeVideo", LazyYoutubeVideo],
["Fullwidthhero1", LazyFullwidthhero1],
["Hero1", LazyHero1],
["Hero2", LazyHero2],
["TabColumns", LazyTabColumns],
["TabImageGrid", LazyTabImageGrid],
["TabLink", LazyTabLink],
["TabSimple", LazyTabSimple],
["Page", LazyPage],
["SellHeader", LazySellHeader],
["CapitalOneCalculator", LazyCapitalOneCalculator],
["CapitalOneLeadsNavigator", LazyCapitalOneLeadsNavigator],
["CapitalOnePrequalification", LazyCapitalOnePrequalification],
["CapitalOneShowroom", LazyCapitalOneShowroom],
["EdmundsTradeIn", LazyEdmundsTradeIn],
["EdmundsTradeInWidget", LazyEdmundsTradeInWidget],
["EdmundsTradeInWidgetSlim", LazyEdmundsTradeInWidgetSlim],
["IpacketButton", LazyIpacketButton],
["SvgoFacebook", LazySvgoFacebook],
["SvgoFeaturesAndroidAuto", LazySvgoFeaturesAndroidAuto],
["SvgoFeaturesAppleCarplay", LazySvgoFeaturesAppleCarplay],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLeaf", LazySvgoLeaf],
["SvgoPlug", LazySvgoPlug],
["SvgoX", LazySvgoX],
["SvgoYoutube", LazySvgoYoutube],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
