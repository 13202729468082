import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }

        if (to.hash) {
            const el: HTMLElement = document.querySelector(
                to.hash,
            ) as HTMLElement

            return {
                left: 0,
                top: (el?.offsetTop ?? 0) - 30,
                behavior: 'smooth',
            }
        }

        if (
            to.fullPath === from.fullPath ||
            stayAtPositionOnInventoryPage(to, from)
        ) {
            return
        }

        return { left: 0, top: 0, behavior: 'smooth' }
    },
}

function stayAtPositionOnInventoryPage(to: any, from: any): boolean {
    const toName: string = to.name?.toString() ?? ''
    const fromName: string = from.name?.toString() ?? ''
    const isInventoryPage: boolean =
        toName.startsWith('category') || toName.startsWith('inventory')

    return toName === fromName && isInventoryPage && to.query.page === undefined
}
