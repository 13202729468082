import type { RuntimeConfig } from 'nuxt/schema'
import type { StoryblokNitroParams } from '~/utils/types/storyblok'
import { StoryblokVersion } from '~/utils/types/storyblok/enums'

export const useFetchStoryblokNitro = async (params: StoryblokNitroParams) => {
    const runTimeConfig: RuntimeConfig = useRuntimeConfig()

    const { slug, token } = params
    const version: StoryblokVersion =
        params.version ?? StoryblokVersion.PUBLISHED

    return await $fetch(
        `${runTimeConfig.public.sb.apiBaseUrl}/stories/${slug}`,
        {
            query: {
                token,
                version,
                language: params.language ?? undefined,
            },
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    )
}
