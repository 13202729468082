export enum ASCEvent {
    PAGE_VIEW = 'asc_pageview',
    CTA_INTERACTION = 'asc_cta_interaction',
    ELEMENT_CONFIGURATION = 'asc_element_configuration',
    CLICK_TO_CALL = 'asc_click_to_call',
    ITEM_PAGEVIEW = 'asc_item_pageview',
    ITEMLIST_PAGEVIEW = 'asc_itemlist_pageview',
    MEDIA_INTERACTION = 'asc_media_interaction',
    FORM_SUBMISSION = 'asc_form_submission',
    FORM_SUBMISSION_PARTS = 'asc_form_submission_parts',
    FORM_SUBMISSION_SALES = 'asc_form_submission_sales',
    FORM_SUBMISSION_SALES_APPT = 'asc_form_submission_sales_appt',
    FORM_SUBMISSION_SERVICE = 'asc_form_submission_service',
    FORM_SUBMISSION_SERVICE_APPT = 'asc_form_submission_service_appt',
    FORM_SUBMISSION_SERVICE_OTHER = 'asc_form_submission_service_other',
    RETAIL_PROCESS = 'asc_retail_process',
    VEHICLE = 'vehicle',
}

export enum ASCPageType {
    ITEM = 'item',
    ITEM_LIST = 'itemlist',
    CONTACT = 'contact',
    DIRECTIONS = 'directions',
    SERVICE = 'service',
    PARTS = 'parts',
    ACCESSORIES = 'accessories',
    BODY_SHOP = 'body_shop',
    HOME = 'home',
    BUILD_TO_ORDER = 'build_to_order',
    CUSTOM = 'custom',
    COMPLAINT = 'complaint',
    EMPLOYMENT = 'employment',
    RECALL = 'recall',
    FINANCE = 'finance',
    CREDIT = 'credit',
    TRADE = 'trade',
    COMMERCIAL = 'commercial',
    SHOWROOM = 'showroom',
    UNKNOWN = 'unknown',
    THANK_YOU = 'thank_you',
    SPECIALS = 'specials',
}

export enum ASCElementType {
    HEADER = 'header',
    BANNER = 'banner',
    POPUP = 'popup',
    FORM = 'form',
    DIGITAL_RETAILING_TOOL = 'digital_retailing_tool',
    TRADE_TOOL = 'trade_tool',
    CHAT_TOOL = 'chat_tool',
    ACCESSIBILITY_TOOL = 'accessibility_tool',
    SEARCH_TOOL = 'search_tool',
    SCHEDULING_TOOL = 'scheduling_tool',
    GALLERY = 'gallery',
    MAP = 'map',
    FOOTER = 'footer',
    VIDEO = 'video',
    ITEM_DETAILS = 'item_details',
    PAGINATION = 'pagination',
    BODY = 'body',
    MENU = 'menu',
}

export enum ASCElementSubType {
    CTA_BUTTON = 'cta_button',
    CTA_ICON = 'cta_icon',
    NAV_BUTTON = 'nav_button',
    NAV_ICON = 'nav_icon',
    NAV_TAB = 'nav_tab',
    HAMBURGER_MENU = 'hamburger_menu',
    MEGA_MENU = 'mega_menu',
    SIDEBAR_MENU = 'sidebar_menu',
    INLINE_MENU = 'inline_menu',
    HYPERLINK = 'hyperlink',
    CONTENT = 'content',
    SHARE_ICON = 'share_icon',
    IMAGE = 'image',
    INPUT_FIELD = 'input_field',
    TOGGLE = 'toggle',
    DROPDOWN = 'dropdown',
    KEBAB_MENU = 'kebab_menu',
    HERO_IMAGE = 'hero_image',
}

export enum ASCEventAction {
    CLICK = 'click',
    SWIPE_LEFT = 'swipe_left',
    SWIPE_RIGHT = 'swipe_right',
    SWIPE_UP = 'swipe_up',
    SWIPE_DOWN = 'swipe_down',
    HOVER = 'hover',
    VIEW = 'view',
    SCROLL = 'scroll',
    FIELD_INPUT = 'field_input',
    KEYSTROKE = 'keystroke',
}

export enum ASCEventActionResult {
    PLAY = 'play',
    RESTART = 'restart',
    VR_PLAY = 'vr_play',
    SPIN = 'spin',
    POPUP = 'popup',
    SLIDE_OUT = 'slideout',
    EXIT = 'exit',
    ERROR = 'error',
    SEARCH = 'search',
    CALC = 'calc',
    START = 'start',
    STOP = 'stop',
    REDIRECT = 'redirect',
    OPEN = 'open',
    CLOSE = 'close',
    INCREASE = 'increase',
    DECREASE = 'decrease',
    CLEAR = 'clear',
    CHECK = 'check',
    UNCHECK = 'uncheck',
    COMPLETE = 'complete',
    DROPDOWN = 'dropdown',
    NONE = 'none',
}

export enum ASCMediaType {
    'VIDEO' = 'video',
    'IMAGE' = 'image',
    'VR' = 'vr',
    'SLIDER' = 'slider',
    'GALLERY' = 'gallery',
    'OFFER' = 'offer',
    '360_PLATFORM' = '360_platform',
    'MAP' = 'map',
}

export enum ASCCommType {
    CHAT = 'chat',
    SMS = 'sms',
    VOICE = 'voice',
    VIDEO = 'video',
    FORM = 'form',
    EMAIL = 'email',
}

export enum ASCCommOutcome {
    APPT_BOOKED = 'appt_booked',
    VEHICLE_ORDERED = 'vehicle_ordered',
    PARTS_ORDERED = 'parts_ordered',
    GET_BACK_COMMITMENT = 'get_back_commitment',
    NOT_OPPORTUNITY = 'not_opportunity',
    ROBOCALL = 'robocall',
    OPPORTUNITY = 'opportunity',
    UNKNOWN = 'unknown',
}

export enum ASCDepartment {
    SALES = 'sales',
    SERVICE = 'service',
    PARTS = 'parts',
    TRADE = 'trade',
    CREDIT = 'credit',
    BODY_SHOP = 'body_shop',
    ADMIN = 'admin',
    ACCESSORIES = 'accessories',
    HR = 'hr',
    CUSTOM_SHOP = 'custom_shop',
    RENTALS = 'rentals',
    UNKNOWN = 'unknown',
    QUICK_LUBE = 'quick_lube',
}

export enum ASCFormType {
    CONSUMER_CONTACT = 'consumer_contact',
    TRADE = 'trade',
    RECALL = 'recall',
    PARTS = 'parts',
    ACCESSORIES = 'accessories',
    SERVICE_APPOINTMENT = 'service_appointment',
    PRE_ORDER = 'pre-order',
    CUSTOM = 'custom',
    BODYSHOP = 'bodyshop',
    FINANCE_CREDIT = 'finance/credit',
    HUMAN_RESOURCES = 'human_resources',
    RESEARCH = 'research',
    SURVEYS = 'surveys',
    UNKNOWN = 'unknown',
    ALERT = 'alert',
    OFFER = 'offer',
    QUOTE = 'quote',
    DOWNLOAD = 'download',
    SHIPMENT = 'shipment',
    PURCHASE = 'purchase',
}

export enum ASCSetVariable {
    BING = 'bing_uet_id',
    FACEBOOK = 'facebook_pixel_id',
    FACEBOOK_TEST_EVENT_CODE = 'facebook_test_event_code',
    GOOGLE_ANALYTICS = 'google_measurement_ids',
    GOOGLE_ADS = 'google_ads_conversion_id',
    PINTEREST = 'pinterest_pixel_id',
    SNAPCHAT = 'snapchat_pixel_id',
    TIKTOK = 'tiktok_pixel_id',
    TWITTER = 'twitter_pixel_id',
}

export enum ASCFlowName {
    FINANCING = 'financing',
    CREDIT_APP = 'credit_app',
    APPOINTMENT = 'appointment',
    TRADE = 'trade',
    COMPARE = 'compare',
    UPLOAD_DATA = 'upload_data',
    LEASE = 'lease',
    DELIVERY = 'delivery',
    ECOMMERCE = 'ecommerce',
    RETENTION = 'retention',
    PAYMENT_CALCULATOR = 'payment_calculator',
    TEST_DRIVE = 'test_drive',
    SOFT_PULL = 'soft_pull',
    DEPOSIT = 'deposit', // not official
}

export enum ASCFlowOutcome {
    START = 'start',
    STOP = 'stop',
    CLOSE = 'close',
    RECALC = 'recalc',
    DOWNLOAD = 'download',
    SUBMIT = 'submit',
    UNLOCK = 'unlock',
    PROCEED = 'proceed',
    LEAD = 'lead',
    APPROVED = 'approved',
    DECLINED = 'declined',
    ERROR = 'error',
    ADDED = 'added',
    REMOVED = 'removed',
    FINISHED = 'finished',
    VIEWED = 'viewed',
    SKIPPED = 'skipped',
}

export enum ASCVehicleStatus {
    IN_STOCK = 'in-stock',
    IN_TRANSIT = 'in-transit',
    SALE_PENDING = 'sale-pending',
    SOLD = 'sold',
}

export enum ASCElementState {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum ASCElementPosition {
    TOP_LEFT = 'top_left',
    TOP_RIGHT = 'top_right',
    BOTTOM_RIGHT = 'bottom_right',
    BOTTOM_LEFT = 'bottom_left',
    TOP_CENTER = 'top_center',
    BOTTOM_CENTER = 'bottom_center',
    CENTER_LEFT = 'center_left',
    CENTER_CENTER = 'center_center',
    CENTER_RIGHT = 'center_right',
    FLOATING = 'floating',
    INLINE = 'inline',
    UNKNOWN = 'unknown',
}
