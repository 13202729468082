export default defineNuxtRouteMiddleware((to) => {
    // skip middleware on server
    if (import.meta.server) return

    const eventStore = useEventStore()
    const storyblokStore = useStoryblokStore()
    const searchStore = useSearchStore()
    const modalStore = useModalStore()
    const { isCrawler } = useDevice()
    const nuxtApp = useNuxtApp()

    // event
    eventStore.resetDataLayer()
    eventStore.resetASCPageType()

    // search
    if (searchStore.isOpen) {
        searchStore.clearSearchResults()
        searchStore.closeSearch()
    }

    if (modalStore.open) {
        modalStore.closeModal()
    }

    // Storyblok
    storyblokStore.resetNavMobileSidebarOpen()
    storyblokStore.resetGate()

    if (storyblokStore.gatingEnabled && !isCrawler) {
        const locale = nuxtApp.$i18n.locale.value
        let toPath = to.path

        if (toPath.startsWith(`/${locale}/`)) {
            toPath = toPath.replace(`/${locale}`, '')
        }

        if (toPath.startsWith('/for-sale/')) {
            toPath = '/for-sale'
        }

        storyblokStore.checkForGate(
            toPath,
            to.query.gate ? to.query.gate.toString() : null,
        )
    }
})
