import { useGtm } from '@gtm-support/vue-gtm'
import type { GtmSupport } from '@gtm-support/core'
import { sendZarazEvent } from '~/composables/asc/sendZarazEvent'
import { sendSnowplowEvent } from '~/composables/asc/sendSnowplowEvent'
import { type ASCEvent, ASCPageType } from '~/utils/types/asc/enums'
import type { ASCEventInterface, ASCVehicle } from '~/utils/types/asc'

export const trackAscEvent = (params: ASCEventParams) => {
    if (import.meta.client) {
        const domainStore = useDomainStore()
        const eventStore = useEventStore()
        const visitorStore = useVisitorStore()
        const gtm: GtmSupport | undefined = useGtm()

        const { $i18n } = useNuxtApp()
        const locale = ($i18n as any).locale.value

        const { eventName, event } = params

        const itemList: ASCVehicle[] = params.itemList ?? []
        const pageType: ASCPageType =
            params.pageType ?? eventStore.ascPageType ?? ASCPageType.UNKNOWN
        const shouldSendZarazEvent: boolean =
            params.shouldSendZarazEvent ?? true
        const leadSubmitted: boolean = params.leadSubmitted ?? false

        const formattedEvent: ASCEventInterface = {
            ...{
                event: eventName,
                page_type: pageType,
                event_owner: 'dealerme',
            },
            ...event,
        }

        if (eventStore.dataLayer === null) {
            eventStore.createDataLayer(
                {
                    store_name: domainStore.autoZnetworkEntity.name,
                    oem_code: 12345,
                    oem_brand: 'independent',
                    affiliation: 'dealerme',
                    currency: 'usd',
                    language: locale ?? 'en',
                    page_type: pageType,
                    items: itemList,
                    measurement_ids: domainStore.measurementIds ?? [],
                    events: [formattedEvent],
                },
                leadSubmitted,
            )

            gtm?.push(eventStore.dataLayer ?? {})
        } else {
            eventStore.addEvent(formattedEvent, leadSubmitted)
        }

        if (domainStore.zarazIsEnabled) {
            if (shouldSendZarazEvent) {
                sendZarazEvent(eventName, eventStore.dataLayer)
            }
        } else {
            // send GA event
            // sendGAEvent(eventName, eventStore.dataLayer)
        }

        // Snowplow
        sendSnowplowEvent(eventName, formattedEvent)

        if (leadSubmitted && !visitorStore.leadFormSubmitted) {
            visitorStore.visitorSubmittedLeadForm()
        }
    }
}

export interface ASCEventParams {
    eventName: ASCEvent
    event: ASCEventInterface
    itemList?: ASCVehicle[]
    pageType?: ASCPageType | null
    shouldSendZarazEvent?: boolean
    leadSubmitted?: boolean
}
