import { trackAscEvent } from '~/composables/asc/trackAscEvent'
import type { Vehicle } from '~/utils/types/inventory'
import type { ASCPageView, ASCVehicle } from '~/utils/types/asc'
import { ASCEvent } from '~/utils/types/asc/enums'

export const trackAscPageViewEvent = (params?: ASCPageViewParams) => {
    const eventStore = useEventStore()

    const vehicle: Vehicle | null = params?.vehicle ?? null
    const vehicles: Vehicle[] | null = params?.vehicles ?? []
    const optionalEvent: ASCEvent | null = params?.optionalEvent ?? null

    const ascVehicle: ASCVehicle | null = vehicle
        ? eventStore.formatVehicleForASC(vehicle)
        : null
    const items: ASCVehicle[] = ascVehicle
        ? [ascVehicle]
        : vehicles.map((vehicle: Vehicle) =>
              eventStore.formatVehicleForASC(vehicle),
          )

    const eventData: ASCPageView = {
        ...{
            error_code: '',
            item_results: items.length,
        },
        ...(ascVehicle ?? {}),
    }

    trackAscEvent({
        eventName: ASCEvent.PAGE_VIEW,
        event: eventData,
        itemList: items,
    })

    if (optionalEvent) {
        trackAscEvent({
            eventName: optionalEvent,
            event: eventData,
        })
    }
}

export interface ASCPageViewParams {
    vehicle?: Vehicle | null
    vehicles?: Vehicle[]
    optionalEvent?: ASCEvent | null
}
