import { ASCPageType } from '~/utils/types/asc/enums'

export default {
    '/': ASCPageType.HOME,
    '/blog': ASCPageType.ITEM_LIST,
    '/careers': ASCPageType.EMPLOYMENT,
    '/contact': ASCPageType.CONTACT,
    '/finance-application': ASCPageType.FINANCE,
    '/inventory': ASCPageType.ITEM_LIST,
    '/sell': ASCPageType.TRADE,
    '/service': ASCPageType.SERVICE,
} as Record<string, any>
