import { type CookieRef, defineNuxtPlugin } from 'nuxt/app'
import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtPlugin(() => {
    const globalStore = useGlobalStore()
    const route: RouteLocationNormalized = useRoute()
    const videoLogCookie: CookieRef<string | undefined | null> =
        useCookie('video_logging')
    const forceDisableLogging: boolean = route.query.video_log === '0'

    if (forceDisableLogging) {
        globalStore.disableVideoLogging(true)
    } else {
        const cookieFound: boolean = videoLogCookie.value !== undefined
        const queryFound: boolean = route.query.video_log !== undefined

        const enableVideoLogging: boolean = queryFound || cookieFound

        if (enableVideoLogging) {
            globalStore.enableVideoLogging()

            if (!cookieFound) {
                videoLogCookie.value = 'true'
            }
        }
    }
})
