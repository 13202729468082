import { useGlobalStore } from '~/stores/global'

export default defineNuxtPlugin(() => {
    const domainStore = useDomainStore()

    // get client ip
    const event = useRequestEvent()
    const ip =
        event?.node?.req.headers['x-real-ip'] ??
        event?.node?.req.headers['x-forwarded-for'] ??
        event?.node?.req.socket?.remoteAddress

    useGlobalStore().assignClientIp(ip)

    const scripts: Record<string, any>[] = []

    // ComplyAuto check
    if (domainStore.complyAuto.enabled) {
        scripts.push(
            {
                src: `https://cdn.complyauto.com/cookiebanner/banner/${domainStore.complyAuto.ids.cookieBanner}/blocker.js`,
            },
            {
                src: 'https://cdn.complyauto.com/cookiebanner/banner.js',
                'data-cacookieconsent-id':
                    domainStore.complyAuto.ids.cookieBanner,
                body: true,
            },
        )
    }

    // Zaraz check
    if (
        useRuntimeConfig().public.env === 'production' &&
        domainStore.zarazIsEnabled
    ) {
        scripts.push({
            src: '/cdn-cgi/zaraz/i.js',
            referrerpolicy: 'origin',
        })
    }

    if (scripts.length > 0) {
        useHead({
            script: scripts,
        })
    }
})
