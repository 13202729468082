export default defineNuxtPlugin((nuxtApp) => {
    const financeApplicationStore = useFinanceApplicationStore()
    const storyblokStore = useStoryblokStore()

    nuxtApp.hook('i18n:localeSwitched', async ({ newLocale }) => {
        financeApplicationStore.resetSettings()

        await storyblokStore.resetAndFetchStoryblokSettings(
            nuxtApp.$config,
            newLocale,
            nuxtApp._route.query,
        )
    })
})
