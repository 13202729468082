import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import type { CookieRef } from 'nuxt/app'
import type { ASCDataLayer, ASCVehicle } from '~/utils/types/asc'
import type { Vehicle } from '~/utils/types/inventory'
import type { EventLog } from '~/utils/types/event'
import { useFetchAutoznetwork } from '~/composables/fetch/useFetchAutoznetwork'
import { useVisitorStore } from '~/stores/visitor'
import { type ASCPageType, ASCVehicleStatus } from '~/utils/types/asc/enums'
import { EventLogType } from '~/utils/types/event/enums'

export const useEventStore = defineStore('event', {
    state: (): State => ({
        dataLayer: null,
        ascPageType: null,
        logging: false,
    }),
    actions: {
        createDataLayer(data: ASCDataLayer, leadSubmitted: boolean = false) {
            const formattedData = this.lowerCaseObjectValues(data)
            this.dataLayer = formattedData

            if (this.logging) {
                this.outputLog({
                    type: EventLogType.CREATE_DATALAYER,
                    event: formattedData,
                    leadSubmitted,
                })
            }
        },
        addEvent(event: any, leadSubmitted: boolean = false) {
            const formattedData = this.lowerCaseObjectValues(event)
            this.dataLayer?.events.unshift(formattedData)

            if (this.logging) {
                this.outputLog({
                    type: EventLogType.ADD_EVENT,
                    event: formattedData,
                    leadSubmitted,
                })
            }
        },
        resetDataLayer() {
            this.dataLayer = null

            if (this.logging) {
                this.outputLog({
                    type: EventLogType.RESET_DATALAYER,
                })
            }
        },
        formatVehicleForASC(vehicle: Vehicle) {
            return {
                item_id: vehicle.vin,
                item_number: vehicle.stock,
                item_price: vehicle.price.selling,
                item_condition: vehicle.type?.toLowerCase(),
                item_year: vehicle.year,
                item_make: vehicle.make,
                item_model: vehicle.model,
                item_variant: vehicle.trim,
                item_color: vehicle.exterior.color,
                item_type: vehicle.body_style,
                item_fuel_type: vehicle.fuel
                    ? vehicle.fuel.toLowerCase()
                    : undefined,
                item_inventory_date: vehicle.created_at
                    ? dayjs(vehicle.created_at).format('YYYY/MM/DD')
                    : undefined,
                item_location: vehicle.location
                    ? useLocationStore().getLocationFullAddress(
                          vehicle.location,
                      )
                    : undefined,
                item_status: vehicle.sold
                    ? ASCVehicleStatus.SOLD
                    : ASCVehicleStatus.IN_STOCK,
            } as ASCVehicle
        },
        lowerCaseObjectValues(obj: any) {
            const ignoreArr: string[] = [
                'items',
                'item_id',
                'measurement_ids',
                'language',
                'element_text',
            ]

            Object.keys(obj).forEach((k: string) => {
                if (obj[k] && !ignoreArr.includes(k)) {
                    if (typeof obj[k] === 'string') {
                        obj[k] = obj[k].toLowerCase()
                    } else if (typeof obj[k] === 'object') {
                        this.lowerCaseObjectValues(obj[k])
                    }
                }
            })

            return obj
        },
        setASCPageType(pageType: ASCPageType) {
            this.ascPageType = pageType
        },
        resetASCPageType() {
            this.ascPageType = null
        },
        enableLogging() {
            this.logging = true
        },
        disableLogging(removeCookie: boolean = false) {
            this.logging = false

            if (removeCookie) {
                this.removeLoggingCookie()
            }
        },
        removeLoggingCookie() {
            const ascLogCookie: CookieRef<string | undefined | null> =
                useCookie('asc_logging')

            ascLogCookie.value = null
        },
        outputLog(data: EventLog) {
            let eventTitle: string = data.event?.event
                ? `ASC Event: ${data.type} - ${data.event.event}`
                : `ASC Event: ${data.type}`

            if (data.leadSubmitted) {
                eventTitle += ' - **LEAD WAS SUBMITTED FROM THIS EVENT**'
            }

            console.log(eventTitle)

            if (data.event) {
                console.log(JSON.stringify(data.event, null, 3))
            }
        },
        sendInsightEvent(event: string, body: InsightEventData) {
            if (useRuntimeConfig().public.env === 'production') {
                const visitorStore = useVisitorStore()

                useFetchAutoznetwork('/insights/event', {
                    method: 'post',
                    fetch: true,
                    body: {
                        ...body,
                        ...{
                            event,
                            userToken: visitorStore.getVisitorId,
                        },
                    },
                }).catch((err) => console.log(err))
            }
        },
    },
})

interface State {
    dataLayer: ASCDataLayer | null
    ascPageType: ASCPageType | null
    logging: boolean
}

interface InsightEventData {
    userToken?: number
    vehicleId?: number
    vin?: string
    queryId?: string | null
    position?: number | null
    filters?: Record<string, any>
}
