export default {
    Autotrader: ['autotrader', 'autotrader.com'],
    'Cars.com': ['cars', 'cars.com'],
    CarGurus: ['cargurus', 'cargurus.com'],
    CARFAX: ['carfax', 'carfax.com'],
    Craigslist: ['craigslist'],
    Edmunds: ['edmunds', 'edmunds.com'],
    Facebook: [
        'fb',
        'm.facebook.com',
        'facebook.com',
        'l.facebook.com',
        'lm.facebook.com',
    ],
    Instagram: ['ig', 'instragram', 'instagram.com'],
    KBB: ['kbb', 'kbb.com'],
    OfferUp: ['offerup', 'offerup.com'],
} as Record<string, any>
