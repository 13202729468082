import type { FetchResponse } from '~/utils/types/global'
import {
    type AutoznetworkAuthHeader,
    getAutoznetworkAuthHeader,
} from '~/composables/fetch/getAutoznetworkAuthHeader'
import { useDomainStore } from '~/stores/domain'

export const useFetchAutoznetwork = async (
    path: string,
    opts?: Record<string, any> | undefined | null,
) => {
    const { orgId, enterpriseId, nitro, fetch, domain } = opts ?? {}

    // only get domain store if the org and enterprise ids are not included
    const domainStore = !orgId && !enterpriseId ? useDomainStore() : null

    const autoZnetworkAuthHeader: AutoznetworkAuthHeader =
        getAutoznetworkAuthHeader(
            opts?.orgId ?? domainStore?.getAutoZnetworkOrgId ?? null,
            opts?.enterpriseId ??
                domainStore?.getAutoZnetworkEnterpriseId ??
                null,
        )

    const headers: HeadersInit = {
        ...(opts?.headers || {}),
        ...{
            [autoZnetworkAuthHeader.header]: autoZnetworkAuthHeader.token,
            'X-DealerMe-Domain':
                domain ?? domainStore?.domain.domain ?? undefined,
        },
    }

    const fullPath: string = `/api/autoznetwork${path}`
    const options = {
        ...opts,
        headers,
    }

    if (nitro || fetch) {
        let res = null
        let error = null

        try {
            res = await $fetch(fullPath, options)
        } catch (e: any) {
            error = e
        }

        return {
            data: {
                value: res,
            },
            error: {
                value: error,
            },
        } as FetchResponse
    } else {
        return useFetch(fullPath, options)
    }
}
