import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(() => {
    const globalStore = useGlobalStore()
    const route = useRoute()
    const paramKeys: string[] = Object.keys(route.query)
    const utmParams: Record<string, any> = {}

    for (let i = 0; i < paramKeys.length; i++) {
        const key = paramKeys[i]
        const value = route.query[key] ?? ''

        if (key.toString().toLowerCase().startsWith('utm_')) {
            utmParams[key] = value
        }
    }

    if (Object.keys(utmParams).length > 0) {
        globalStore.utm = utmParams
    }
})
