// no TS support: https://github.com/AmazingDreams/vue-matomo/issues/116

// import VueMatomo from 'vue-matomo'

export default defineNuxtPlugin(() => {
    // const settings = useDomainStore().settings || {}
    // const isEnabled = getSetting(settings, 'vendor.matamo.enabled')
    // const siteId = getSetting(settings, 'vendor.matamo.siteId')
    //
    // if (isEnabled) {
    //     nuxtApp.vueApp.use(VueMatomo, {
    //         host: 'https://autoznetwork.matomo.cloud',
    //         siteId,
    //         router: nuxtApp.$router, // enables automatically registering page views on the router
    //         enableLinkTracking: true,
    //         requireConsent: false,
    //         trackInitialView: true,
    //         disableCookies: true,
    //         requireCookieConsent: false,
    //     })
    // }
})
