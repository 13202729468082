import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const {
        public: { sentry },
    } = useRuntimeConfig()

    if (!sentry.dsn) {
        return
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        integrations: [
            Sentry.browserTracingIntegration(),
            // Sentry.replayIntegration(),

            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: [sentry.applicationId],

                // Defines how to handle errors that contain third party stack frames.
                // Possible values are:
                // - 'drop-error-if-contains-third-party-frames'
                // - 'drop-error-if-exclusively-contains-third-party-frames'
                // - 'apply-tag-if-contains-third-party-frames'
                // - 'apply-tag-if-exclusively-contains-third-party-frames'
                behaviour: 'drop-error-if-contains-third-party-frames',
            }),
        ],

        // Configure this whole part as you need it!

        tracesSampleRate: 0.2, // Change in prod

        // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#configuration-options
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        // Default is ['localhost', /^\//]
        // This means that by default, tracing headers are only attached to requests that contain localhost in their URL or requests whose URL starts with a '/' (for example GET /api/v1/users).
        // tracePropagationTargets: ['localhost', 'https://your-server.com'],

        replaysSessionSampleRate: 1.0, // Change in prod
        replaysOnErrorSampleRate: 1.0, // Change in prod if necessary

        ignoreErrors: [],
    })
})
