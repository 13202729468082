import { defineNuxtPlugin } from 'nuxt/app'
import { sendZarazSet } from '~/composables/asc/sendZarazSet'

export default defineNuxtPlugin(() => {
    if (
        useRuntimeConfig().public.env === 'production' &&
        useDomainStore().zarazIsEnabled
    ) {
        const domainStore = useDomainStore()
        const analyticsSettings: Record<string, any> | null =
            domainStore.getAnalyticsSettings

        if (analyticsSettings) {
            Object.keys(analyticsSettings).forEach((key: string) => {
                sendZarazSet(key, analyticsSettings[key])
            })
        }
    }
})
