/* uncomment the following line to override default position */
/* s.setAttribute("data-position", 3); */
/* uncomment the following line to override default size (values: small, large) */
/* s.setAttribute("data-size", "small"); */
/* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.) */
/* s.setAttribute("data-language", "language"); */
/* uncomment the following line to override color set via widget (e.g., #053f67) */
/* s.setAttribute("data-color", "#053e67"); */
/* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text) */
/* s.setAttribute("data-type", "1"); */
/* s.setAttribute("data-statement_text:", "Our Accessibility Statement"); */
/* s.setAttribute("data-statement_url", "http://www.example.com/accessibility")"; */
/* uncomment the following line to override support on mobile devices */
/* s.setAttribute("data-mobile", true); */
/* uncomment the following line to set custom trigger action for accessibility menu */
/* s.setAttribute("data-trigger", "triggerId") */

export default defineNuxtPlugin(() => {
    const settings = useDomainStore().settings || {}
    const isEnabled = getSetting(settings, 'vendor.userway.enabled')
    const position = getSetting(settings, 'vendor.userway.position')

    if (isEnabled) {
        useHead({
            script: [
                {
                    src: 'https://cdn.userway.org/widget.js',
                    // async: true,
                    type: 'text/javascript',
                    'data-account': '8M62pkhYAk',
                    'data-position': position ?? 2,
                },
            ],
        })
    }
})
