import pageSchema from '~/utils/ascPageTypeSchema'

export default defineNuxtRouteMiddleware((to) => {
    // skip middleware on server
    if (import.meta.server) return

    const { $i18n } = useNuxtApp()
    const locale = ($i18n as any).locale.value

    let routePathWithoutLocale: string = to.path.replace(`/${locale}`, '')

    if (routePathWithoutLocale === '') {
        routePathWithoutLocale = '/'
    }

    const pageType = pageSchema[routePathWithoutLocale] ?? null

    if (pageType) {
        useEventStore().setASCPageType(pageType)
    }
})
