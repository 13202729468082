import validate from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45resets_45global from "/Users/chris/Projects/autoz/dealerme/middleware/01.resets.global.ts";
import _02_45assign_45asc_45page_45type_45global from "/Users/chris/Projects/autoz/dealerme/middleware/02.assignASCPageType.global.ts";
import _03_45send_45page_45view_45global from "/Users/chris/Projects/autoz/dealerme/middleware/03.sendPageView.global.ts";
import manifest_45route_45rule from "/Users/chris/Projects/autoz/dealerme/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45resets_45global,
  _02_45assign_45asc_45page_45type_45global,
  _03_45send_45page_45view_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}