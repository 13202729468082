<template>
    <div class="dm-backdrop w-full h-full">
        <div class="dm-background bg-gray-100">
            <NuxtLoadingIndicator class="dm-loading-indicator" :color="false" />
            <NuxtLayout>
                <NuxtPage />
            </NuxtLayout>
        </div>
    </div>
</template>

<script setup lang="ts">
import { provideUseId } from '@headlessui/vue'
import { usePageHead } from '~/composables/head'
import { usePageJsonld } from '~/composables/jsonld'
import { useLocationStore } from '~/stores/location'

provideUseId(() => useId())

const domainStore = useDomainStore()

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    // identifierAttribute: 'id', // adds an id to the meta property with i18n
    addSeoAttributes: true,
})

// Pass i18n into useCustomHead
// https://i18n.nuxtjs.org/docs/guide/seo
usePageHead(domainStore, useRequestURL(), i18nHead)
usePageJsonld(domainStore.domain, useLocationStore().locations)
</script>

<!-- background: #d1d5db; -->
<style type="scss">
html,
body,
#__nuxt {
    @apply bg-white;
}

.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(0.5rem);
}
</style>
