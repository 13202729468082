export enum WebImageType {
    WEBP = 'webp',
    AVIF = 'avif',
    JPEG = 'jpeg',
}

export enum VehicleAssetType {
    YOUTUBE = 'youtube',
    VIDEO = 'video',
    VIMEO = 'vimeo',
}

export enum VehicleImageFit {
    COVER = 'cover',
    CONTAIN = 'contain',
    FILL = 'fill',
    INSIDE = 'inside',
    OUTSIDE = 'outside',
}

export enum VehicleImagePosition {
    TOP = 'top',
    RIGHT_TOP = 'right_top',
    RIGHT = 'right',
    RIGHT_BOTTOM = 'right_bottom',
    BOTTOM = 'bottom',
    LEFT_BOTTOM = 'left_bottom',
    LEFT = 'left',
    LEFT_TOP = 'left_top',
    ENTROPY = 'entropy',
    ATTENTION = 'attention',
}

export enum VehicleImageRatio {
    RATIO_1_1 = 1,
    RATIO_3_2 = 0.66666666666, // 2/3
    RATIO_4_3 = 0.75, // 3/4
    RATIO_16_9 = 0.5625, // 9/16
    RATIO_9_16 = 1.77777777778, // 16/9
}

export enum VehicleImageSequenceStepType {
    EXTERIOR = 'exterior',
    INTERIOR = 'interior',
}
