import { type CookieRef, defineNuxtPlugin } from 'nuxt/app'
import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtPlugin(() => {
    const eventStore = useEventStore()
    const route: RouteLocationNormalized = useRoute()
    const ascLogCookie: CookieRef<string | undefined | null> =
        useCookie('asc_logging')
    const forceDisableLogging: boolean = route.query.asc_log === '0'

    if (forceDisableLogging) {
        eventStore.disableLogging(true)
    } else {
        const cookieFound: boolean = ascLogCookie.value !== undefined
        const queryFound: boolean = route.query.asc_log !== undefined

        const enableAscLogging: boolean = queryFound || cookieFound

        if (enableAscLogging) {
            eventStore.enableLogging()

            if (!cookieFound) {
                ascLogCookie.value = 'true'
            }
        }
    }
})
