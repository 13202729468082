import type { RuntimeConfig } from 'nuxt/schema'
import type { StoryblokNitroParams } from '~/utils/types/storyblok'
import { StoryblokSettingsType } from '~/stores/storyblok'
import { StoryblokVersion } from '~/utils/types/storyblok/enums'

export const assignStoryblokSettings = async (
    $config: RuntimeConfig,
    locale: string,
    query: Record<string, any>,
    type: StoryblokSettingsType,
) => {
    const domainStore = useDomainStore()
    const storyblokStore = useStoryblokStore()

    const previewToken: string | undefined = domainStore.space?.preview_token
    const storyblokQueryFound: boolean = getStoryblokQuery(query, previewToken)
    const isDraft: boolean =
        $config.public.env !== 'production' || storyblokQueryFound

    const domainStoryblokToken: string | undefined = isDraft
        ? domainStore.space?.preview_token
        : domainStore.space?.public_token

    storyblokStore.version = isDraft
        ? StoryblokVersion.DRAFT
        : StoryblokVersion.PUBLISHED

    storyblokStore.token =
        ($config.public.sb.skeleton.apiKey || domainStoryblokToken) ?? ''

    const params: StoryblokNitroParams = {
        token: storyblokStore.token,
        version: storyblokStore.version,
        language: locale,
    }

    if (type === StoryblokSettingsType.SETTINGS) {
        if (!storyblokStore.settingsLoaded) {
            await storyblokStore.loadSettings(
                StoryblokSettingsType.SETTINGS,
                params,
            )
        }
    } else if (type === StoryblokSettingsType.VDP_SETTINGS) {
        if (!storyblokStore.vdpSettingsLoaded) {
            await storyblokStore.loadSettings(
                StoryblokSettingsType.VDP_SETTINGS,
                params,
            )
        }
    }
}

function getStoryblokQuery(
    query: Record<string, any>,
    previewToken: string | undefined,
): boolean {
    const spaceId = query['_storyblok_tk[space_id]'] ?? null

    return previewToken !== undefined && spaceId !== null
}
