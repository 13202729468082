export const getAutoznetworkAuthHeader = (
    orgId: string | number | undefined | null,
    enterpriseId: string | number | undefined | null,
) => {
    return enterpriseId
        ? ({
              header: 'X-AutozNetwork-Enterprise-Id',
              token: enterpriseId,
          } as AutoznetworkAuthHeader)
        : ({
              header: 'X-AutozNetwork-Organization-Id',
              token: orgId,
          } as AutoznetworkAuthHeader)
}

export interface AutoznetworkAuthHeader {
    header: string
    token: string | number | undefined | null
}
