<template>
    <div class="dm-backdrop w-full h-full">
        <div class="dm-background mx-auto max-w-wide bg-white">
            <NuxtLayout v-if="!fatalError">
                <div
                    class="grid full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
                >
                    <div class="text-center">
                        <p class="text-base font-semibold text-blue-600">
                            {{ error.statusCode }}
                        </p>
                        <div v-if="error.statusCode === 404">
                            <h1
                                class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
                            >
                                Page not found
                            </h1>
                            <p class="mt-6 text-base leading-7 text-gray-600">
                                Sorry, we couldn't find the page you’re looking
                                for.
                            </p>
                        </div>
                        <div v-else>
                            <h1
                                class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
                            >
                                An error occurred
                            </h1>
                            <p class="mt-6 text-base leading-7 text-gray-600">
                                We apologize for the inconvenience.
                            </p>
                            <p class="mt-6 text-base leading-7 text-gray-600">
                                {{ error.message }}
                            </p>
                            <div v-if="showStackTrace" class="mt-10">
                                <h2
                                    v-if="isLocal"
                                    class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text4xl"
                                >
                                    Local Development Stacktrace
                                </h2>

                                <h2
                                    v-else
                                    class="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text4xl"
                                >
                                    🥷 Stacktrace 🥷
                                </h2>
                                <div
                                    class="mt-6 overflow-hidden rounded-lg bg-gray-100"
                                >
                                    <div class="px-4 py-5 sm:p-6">
                                        <div
                                            class="text-left"
                                            v-html="error.stack"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="mt-10 flex items-center justify-center gap-x-6"
                        >
                            <DealerMeNuxtLink href="/" class="btn">
                                Go back home
                            </DealerMeNuxtLink>
                            <DealerMeNuxtLink
                                href="/contact"
                                class="text-sm font-semibold text-gray-900"
                            >
                                Contact support
                                <span aria-hidden="true">&rarr;</span>
                            </DealerMeNuxtLink>
                        </div>
                    </div>
                </div>
            </NuxtLayout>

            <div v-else>
                <div class="p-3">
                    {{ error.statusCode }}: {{ error.message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { NuxtError } from '#app'

const props = defineProps({
    error: {
        type: Object as PropType<NuxtError>,
        required: true,
    },
})

const fatalError = computed(() => props.error.statusCode === 503)
const isLocal = computed(() => useRuntimeConfig().public.env === 'local')
const isNinja = computed(() => useRoute().query.ninja)
const showStackTrace = computed(() => isLocal.value || isNinja.value)

useHead({
    title: props.error.statusCode === 404 ? 'Page Not Found' : 'Error',
})
</script>
