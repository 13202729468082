import { ASCSetVariable } from '~/utils/types/asc/enums'

export const buildAnalyticsSettings = (
    settings: Record<string, any>,
): AnalyticSettings | null => {
    const returnObj: AnalyticSettings = {}

    const bing = settings.bing || null
    const facebookPixel = settings.facebook?.pixelId || null
    const facebookEvent = settings.facebook?.testEventCode || null
    const googleAnalytics = settings.google?.analytics || null
    const googleAds = settings.google?.ads || null
    const pinterest = settings.pinterest || null
    const snapchat = settings.snapchat || null
    const tiktok = settings.tiktok || null
    const twitter = settings.twitter || null

    if (bing) {
        returnObj[ASCSetVariable.BING] = bing
    }

    if (facebookPixel) {
        returnObj[ASCSetVariable.FACEBOOK] = facebookPixel
    }

    if (facebookEvent) {
        returnObj[ASCSetVariable.FACEBOOK_TEST_EVENT_CODE] = facebookEvent
    }

    if (googleAnalytics) {
        // set this as the first GA id until we figure out multiple
        returnObj[ASCSetVariable.GOOGLE_ANALYTICS] = Array.isArray(
            googleAnalytics,
        )
            ? googleAnalytics[0]
            : googleAnalytics
    }

    if (googleAds) {
        returnObj[ASCSetVariable.GOOGLE_ADS] = googleAds
    }

    if (pinterest) {
        returnObj[ASCSetVariable.PINTEREST] = pinterest
    }

    if (snapchat) {
        returnObj[ASCSetVariable.SNAPCHAT] = snapchat
    }

    if (tiktok) {
        returnObj[ASCSetVariable.TIKTOK] = tiktok
    }

    if (twitter) {
        returnObj[ASCSetVariable.TWITTER] = twitter
    }

    return Object.keys(returnObj).length > 0 ? returnObj : null
}

export type AnalyticSettings = {
    [key in ASCSetVariable]?: string | undefined
}
