import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import dayjs from 'dayjs'
import type { ASCEventInterface } from '~/utils/types/asc'
import {
    type IgluSchemaData,
    mapAscEventToIgluSchema,
} from '~/composables/asc/utility/mapAscEventToIgluSchema'
import type { ASCEvent } from '~/utils/types/asc/enums'

export const sendSnowplowEvent = (
    eventName: ASCEvent,
    event: ASCEventInterface,
) => {
    if (useRuntimeConfig().public.env === 'production') {
        const schemaItem: IgluSchemaData | null = mapAscEventToIgluSchema(
            eventName,
            event as Record<string, unknown>,
        )

        if (schemaItem !== null) {
            if (schemaItem.data.item_inventory_date) {
                schemaItem.data.item_inventory_date = dayjs(
                    schemaItem.data.item_inventory_date as string,
                ).toISOString()
            }

            trackSelfDescribingEvent({
                event: {
                    schema: schemaItem.schema,
                    data: schemaItem.data,
                },
            })
        }
    }
}
