import type { ASCDataLayer } from '~/utils/types/asc'
import type { ASCEvent } from '~/utils/types/asc/enums'

export const sendZarazEvent = (
    eventName: ASCEvent,
    data: ASCDataLayer | null,
) => {
    if (
        useRuntimeConfig().public.env === 'production' &&
        useDomainStore().zarazIsEnabled
    ) {
        const zaraz = (window as any).zaraz

        if (zaraz) {
            zaraz.track(eventName, data ?? {})
        }
    }
}
