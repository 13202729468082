export enum DomainEntityType {
    ENTERPRISE = 'enterprise',
    ORGANIZATION = 'organization',
}

export enum ModalSize {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XL2 = '2xl',
    XL3 = '3xl',
    XL4 = '4xl',
    XL5 = '5xl',
    FULL = 'full',
}

export enum ProgressBarColor {
    GRAY = 'gray',
    LIGHT_GRAY = 'lightGray',
    RED = 'red',
    GREEN = 'green',
}
