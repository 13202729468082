import { trackAscPageViewEvent } from '~/composables/asc/events/Pageview'

export default defineNuxtRouteMiddleware((to) => {
    // skip middleware on server
    if (import.meta.server) return

    if (
        useRuntimeConfig().public.env === 'production' &&
        useDomainStore().zarazIsEnabled
    ) {
        // add page view call
        const zaraz = (window as any).zaraz

        if (zaraz) {
            setTimeout(() => zaraz.spaPageview(), 250)
        }
    }

    const ignoreArr: string[] = [
        'inventory', // SRP
        'for-sale-slug', // VDP
        'slug', // Storyblok Page
    ]

    const { $i18n } = useNuxtApp()
    const locale = ($i18n as any).locale.value
    const routeNameWithoutLocale: string = (to.name as string).replace(
        `___${locale}`,
        '',
    )

    // send asc_pageview event if not in list of ignore pages
    if (!ignoreArr.includes(routeNameWithoutLocale)) {
        trackAscPageViewEvent()
    }
})
