import { defineNuxtPlugin, useRouter } from 'nuxt/app'
import { createGtm, type VueGtmUseOptions } from '@gtm-support/vue-gtm'
import type { Router } from 'vue-router'

export default defineNuxtPlugin((nuxt) => {
    const domainStore = useDomainStore()
    const gtmId: string | null = domainStore.domain.gtm_id ?? null
    const router: Router = useRouter()

    const pluginOptions: VueGtmUseOptions = {
        vueRouter: router
            ? (router as VueGtmUseOptions['vueRouter'])
            : undefined,
        ...{
            id: gtmId ?? 'GTM-123456',
            enabled: gtmId !== null,
            dataLayerName: 'asc_datalayer',
        },
    }

    nuxt.vueApp.use(createGtm(pluginOptions))
})
