export const sendZarazSet = (
    key: string,
    value: any,
    scope: string | null = null,
) => {
    if (
        useRuntimeConfig().public.env === 'production' &&
        useDomainStore().zarazIsEnabled
    ) {
        const zaraz = (window as any).zaraz

        if (zaraz) {
            zaraz.set(key, value, scope ? { scope } : null)
        }
    }
}
