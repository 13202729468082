import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(async () => {
    const clarityApiKey =
        getSetting(useDomainStore().settings || {}, 'clarity.apiKey') ?? null

    if (
        useRuntimeConfig().public.env === 'production' &&
        clarityApiKey !== null
    ) {
        const { proxy } = useScriptClarity({
            id: clarityApiKey,
        })

        const { userAgent } = useDevice()

        await proxy.clarity('identify', useVisitorStore().getVisitorId ?? '')
        await proxy.clarity('set', 'userAgent', userAgent)
    }
})
