import { defineStore } from 'pinia'
import { load as loadFingerprintJS } from '@fingerprintjs/fingerprintjs'
import { newTracker, setUserId, trackPageView } from '@snowplow/browser-tracker'
import dayjs from 'dayjs'
import type { Fingerprint } from '~/utils/types/fingerprint'
import type {
    ClientCFData,
    FormData,
    FormDataAddress,
    FormDataPerson,
    RecentlyViewedItem,
} from '~/utils/types/visitor'
import { removeEmptyValuesFromObject } from '~/utils/functions'

export const useVisitorStore = defineStore('visitor', {
    state: (): State => ({
        fingerprint: null,
        cfData: null,
        recentlyViewed: [],
        leadFormSubmitted: false,
        forms: {},
    }),
    persist: {
        storage: piniaPluginPersistedstate.localStorage(),
        pick: ['fingerprint', 'recentlyViewed', 'forms'],
    },
    getters: {
        getVisitorId(state) {
            // TODO - anonymous-orgId-visitorId
            return state.fingerprint?.visitorId
        },
        getRecentlyViewedByDays(state) {
            return (days: number) => {
                const daysAgo =
                    days !== null
                        ? dayjs().utc().subtract(7, 'days').startOf('day')
                        : null

                return state.recentlyViewed.filter((item: RecentlyViewedItem) =>
                    dayjs(item.updated).isSameOrAfter(daysAgo),
                )
            }
        },
        getRecentlyViewedLastSevenDays(state) {
            const sevenDaysAgo = dayjs()
                .utc()
                .subtract(7, 'days')
                .startOf('day')

            return state.recentlyViewed.filter((item: RecentlyViewedItem) =>
                dayjs(item.updated).isSameOrAfter(sevenDaysAgo),
            )
        },
        vehicleViewedRecently: (state) => {
            return (vehicleId: number, days: number | null = null) => {
                const daysAgo =
                    days !== null
                        ? dayjs().utc().subtract(7, 'days').startOf('day')
                        : null

                return state.recentlyViewed.find(
                    (item: RecentlyViewedItem) =>
                        item.id === vehicleId &&
                        (daysAgo === null ||
                            dayjs(item.updated).isSameOrAfter(daysAgo)),
                )
            }
        },
    },
    actions: {
        initializeFingerprintAndSnowplow() {
            if (this.fingerprint === null) {
                const fpPromise = loadFingerprintJS()

                fpPromise
                    .then((fp) => fp.get())
                    .then((result: any) => {
                        this.fingerprint = result

                        if (useRuntimeConfig().public.env === 'production') {
                            this.initializeSnowplow()
                        }
                    })
            }
        },
        async addFingerprintToQuery(params: Record<string, any>) {
            const locationStore = useLocationStore()

            if (locationStore.geo === null || this.fingerprint === null) {
                await this.waitForGeoAndFingerprint(locationStore)
            }

            return Object.assign(JSON.parse(JSON.stringify(params)), {
                visitor_id: this.getVisitorId,
                coordinates: locationStore.getCurrentLocationCoordinatesString,
                radius: null,
                precision: null,
            })
        },
        async waitForGeoAndFingerprint(locationStore: any) {
            const startTime = new Date().getTime()

            while (true) {
                if (locationStore.geo !== null && this.fingerprint !== null) {
                    break
                } else if (new Date().getTime() > startTime + 3000) {
                    break
                }

                await new Promise((resolve) => setTimeout(resolve, 500))
            }
        },
        setClientCFData(data: ClientCFData) {
            this.cfData = data
        },
        visitorSubmittedLeadForm() {
            if (!this.leadFormSubmitted) {
                this.leadFormSubmitted = true

                const storyblokStore = useStoryblokStore()

                // check if gating enabled
                if (storyblokStore.gatingEnabled) {
                    storyblokStore.openGate()
                }
            }
        },
        initializeSnowplow() {
            const domain: string = useDomainStore().domain.domain
            const visitorStore = useVisitorStore()

            newTracker('spc', domain, {
                appId: domain,
                discoverRootDomain: true,
                postPath: '/spc', // Collector path override
                cookieSameSite: 'Lax', // Recommended
                eventMethod: 'post',
                bufferSize: 1,
                cookieLifetime: 63072000,
                stateStorageStrategy: 'cookieAndLocalStorage',
                contexts: {
                    webPage: true,
                },
            })

            const visitorId: string | null = visitorStore.getVisitorId ?? null

            if (visitorId) {
                setUserId(visitorId)
            }

            // enableActivityTracking({
            //     minimumVisitLength: 30,
            //     heartbeatDelay: 10,
            // })

            trackPageView()
        },
        addToRecentlyViewed(id: number) {
            const now: string = dayjs().utc().toISOString()
            const newItem: RecentlyViewedItem = {
                added: now,
                updated: now,
                count: 1,
                id,
            }

            if (this.recentlyViewed.length === 0) {
                this.recentlyViewed.push(newItem)
            } else if (this.recentlyViewed[0].id === id) {
                this.recentlyViewed[0].count++
                this.recentlyViewed[0].updated = now
            } else {
                // attempt to find in current vehicles
                const findIndex = this.recentlyViewed.findIndex(
                    (item: RecentlyViewedItem) => item.id === id,
                )

                if (findIndex > -1) {
                    const item: RecentlyViewedItem = {
                        ...this.recentlyViewed[findIndex],
                        ...{
                            count: this.recentlyViewed[findIndex].count + 1,
                            updated: now,
                        },
                    }

                    // remove from array
                    this.recentlyViewed.splice(findIndex, 1)

                    // add to beginning of array
                    this.recentlyViewed.unshift(item)
                } else {
                    this.recentlyViewed.unshift(newItem)
                }
            }

            if (this.recentlyViewed.length > 100) {
                this.recentlyViewed = this.recentlyViewed.slice(0, -1)
            }
        },
        updateFormDataPerson(data: FormDataPerson) {
            this.forms.person = {
                ...(this.forms.person ?? {}),
                ...removeEmptyValuesFromObject(data),
            }
        },
        updateFormDataAddress(data: FormDataAddress) {
            this.forms.address = {
                ...(this.forms.address ?? {}),
                ...removeEmptyValuesFromObject(data),
            }
        },
    },
})

interface State {
    fingerprint: Fingerprint | null
    cfData: ClientCFData | null
    recentlyViewed: RecentlyViewedItem[]
    leadFormSubmitted: boolean
    forms: FormData
}
