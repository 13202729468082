import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(() => {
    if (useDomainStore().cargurusEnabled) {
        useHead({
            script: [
                {
                    src: '/cargurus.js',
                    type: 'text/javascript',
                    async: true,
                },
            ],
        })
    }
})
