import type { FetchResponse } from '~/utils/types/global'
import { useDomainStore } from '~/stores/domain'

export const useFetchDealerMe = async (
    path: string,
    opts?: Record<string, any> | undefined | null,
) => {
    const { domain, nitro, fetch } = opts ?? {}

    const headers: HeadersInit = {
        ...(opts?.headers || {}),
        ...{
            'X-DealerMe-Domain':
                domain ?? useDomainStore().domain.domain ?? undefined,
        },
    }

    const fullPath: string = `/api/dealerme${path}`
    const options = {
        ...opts,
        headers,
    }

    if (nitro || fetch) {
        let res = null
        let error = null

        try {
            res = await $fetch(fullPath, options)
        } catch (e: any) {
            error = e
        }

        return {
            data: {
                value: res,
            },
            error: {
                value: error,
            },
        } as FetchResponse
    } else {
        return useFetch(fullPath, options)
    }
}
