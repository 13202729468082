import { defineStore } from 'pinia'
import { useFetchAutoznetwork } from '~/composables/fetch/useFetchAutoznetwork'
import { useInventoryStore } from '~/stores/inventory'

export const useSearchStore = defineStore('search', {
    state: (): State => ({
        isOpen: false,
        isLoadingSearch: false,
        searchQuery: '',
        searchResults: null,
    }),
    getters: {
        getIsOpen(state) {
            return state.isOpen
        },
        getIsLoadingSearch(state) {
            return state.isLoadingSearch
        },
        getSearchQuery(state) {
            return state.searchQuery
        },
        getSearchResults(state) {
            return state.searchResults ? state.searchResults : null
        },
    },
    actions: {
        openSearch() {
            this.isOpen = true
        },
        closeSearch() {
            this.isOpen = false
        },
        clearSearchResults() {
            this.searchQuery = ''
            this.isLoadingSearch = false
            this.searchResults = null
        },
        async fetchSearchResults(query: string) {
            const visitorStore = useVisitorStore()

            this.isLoadingSearch = true
            this.searchQuery = query

            const params = await visitorStore.addFingerprintToQuery({
                query,
                limit: 10,
            })

            const { data } = await useFetchAutoznetwork(`/inventory/search`, {
                query: useInventoryStore().addDomainQueryParams(params, false),
                fetch: true,
            })

            this.searchResults = data.value
            this.isLoadingSearch = false
        },
    },
})

interface State {
    isOpen: boolean
    isLoadingSearch: boolean
    searchQuery: string
    searchResults: Record<string, any> | null
}
