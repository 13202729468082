import type { RuntimeConfig } from 'nuxt/schema'
import type { AutoZnetworkEntity, Domain } from '~/utils/types/global'

export const usePageHead = (
    domainStore: Record<string, any>,
    url: any,
    i18nHead: any,
) => {
    const entity: AutoZnetworkEntity = domainStore.domain.autoz_entity

    useHead({
        titleTemplate: (titleChunk: string | undefined) => {
            const dealership = entity?.name
            return titleChunk ? `${titleChunk} - ${dealership}` : dealership
        },
        htmlAttrs: {
            lang: i18nHead.value.htmlAttrs!.lang,
            dir: i18nHead.value.htmlAttrs!.dir,
        },
        link: [
            ...buildFavicons(domainStore),
            ...buildPrependDomainData(domainStore, i18nHead.value.link, 'href'),
        ],
        meta: [
            ...buildPrependDomainData(
                domainStore,
                i18nHead.value.meta,
                'content',
                ['i18n-og-url'],
            ),
            {
                name: 'description',
                content: entity?.name,
            },
            { name: 'twitter:card', content: 'summary_large_image' },
            // { name: 'twitter:site', content: '@zernonia' },
            {
                name: 'twitter:title',
                content: entity?.name,
            },
            {
                name: 'twitter:description',
                content: entity?.name,
            },
            // {
            //     name: 'twitter:image',
            //     content: image,
            // },
            { property: 'og:type', content: 'website' },
            {
                property: 'og:title',
                content: entity?.name,
            },
            { property: 'og:url', content: url.href },
            // {
            //     property: 'og:image',
            //     content: 'https://keypress.blog/og.png',
            // },
            // { property: 'og:image:type', content: 'image/png' },
            {
                property: 'og:description',
                content: entity?.name,
            },
        ],
        style: [
            {
                textContent: domainStore.getTheme ? domainStore.getTheme : null,
            },
        ],
        // Loop through the scripts to inject them here.
        // script: [
        //     {
        //         innerHTML: 'console.log("Developed by autoZnetwork.")',
        //         type: 'text/javascript',
        //         // src: '.js',
        //         // async: true,
        //     },
        // ],
        // noscript: [
        //     {
        //         innerHTML: 'console.log("Developed by autoZnetwork.")',
        //     },
        // ],
    })
}

function buildFavicons(domainStore: Record<string, any>) {
    const domain: Domain = domainStore.domain
    const runTimeConfig: RuntimeConfig = useRuntimeConfig()

    if (!domain.has_favicon || runTimeConfig.public.env !== 'production') {
        return []
    }

    const sizes: string[] = [
        '16x16',
        '32x32',
        '72x72',
        '96x96',
        '128x128',
        '144x144',
        '152x152',
        '192x192',
        '384x384',
        '512x512',
    ]

    const baseUrl: string = `${runTimeConfig.public.faviconDomain}/${domain.domain}/favicons`

    return [
        {
            rel: 'shortcut icon',
            type: 'image/x-icon',
            href: `${baseUrl}/favicon.ico`,
        },
    ].concat(
        sizes.map((size: string) => ({
            rel: 'icon',
            type: 'image/png',
            href: `${baseUrl}/favicon-${size}.png`,
            sizes: size,
        })),
    )
}

function buildPrependDomainData(
    domainStore: Record<string, any>,
    data: Record<string, any>[],
    field: string,
    ids: string[] | null = null,
): Record<string, any>[] {
    const domain: string = domainStore.domain.domain
    const protocol: 'http' | 'https' = domain.includes('localhost')
        ? 'http'
        : 'https'

    return data.map((item: Record<string, any>) => {
        if (
            (ids === null || ids.includes(item.hid)) &&
            item[field] !== undefined
        ) {
            item[field] = `${protocol}://${domain}${item[field]}`
        }

        return item
    })
}
