import { StoryblokVue, apiPlugin } from '@storyblok/vue'

export default defineNuxtPlugin(({ vueApp }) => {
    vueApp.use(StoryblokVue, {
        accessToken: useStoryblokStore().token,
        apiOptions: {
            region: 'us',
            // cache: {
            //     type: 'custom',
            //     custom: {
            //         flush() {
            //             console.log('all right')
            //         },
            //     },
            // },
        },
        use: [apiPlugin],
    })
})
