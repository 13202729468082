import type { AutoZnetworkEntity, Domain } from '~/utils/types/global'
import type { Location } from '~/utils/types/location'
import { HourStatus } from '~/utils/types/location/enums'

export const usePageJsonld = (domain: Domain, locations: Location[]) => {
    const entity: AutoZnetworkEntity = domain.autoz_entity

    // https://schema.org/AutoDealer
    useJsonld({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: entity?.name,
        logo: useStoryblokStore().getLogo?.image.filename ?? undefined,
        url: `https://${domain.domain}`,
        telephone: entity?.phone_number,
        location: locations?.map((location: Location) => ({
            '@type': 'AutoDealer',
            parentOrganization: entity?.name,
            name: location.name,
            image: location.image,
            address: {
                '@type': 'PostalAddress',
                streetAddress: location.address.street_address,
                postalCode: location.address.zip,
                addressRegion: location.address.state,
                addressLocality: location.address.city,
                addressCountry: 'US',
            },

            telephone: location.phone_number,
            latitude: location.coordinates.latitude,
            longitude: location.coordinates.longitude,
            openingHours: buildOpeningHours(location) ?? undefined,
            priceRange: '$$$',
        })),
    })

    function buildOpeningHours(location: Location) {
        const returnArr: string[] = []
        const hours = getSetting(location.settings, 'sales.hoursOfOperation')

        if (hours) {
            const groupedHours = groupHoursOfOperation(hours, true)
            const keys: string[] = Object.keys(groupedHours)

            for (let i = 0; i < keys.length; i++) {
                const hourGrouping = groupedHours[keys[i]]

                if (
                    hourGrouping.open !== null &&
                    hourGrouping.open !== HourStatus.CLOSED
                ) {
                    returnArr.push(
                        `${keys[i]} ${hourGrouping.open}-${hourGrouping.close}`,
                    )
                }
            }
        }

        return returnArr.length > 0 ? returnArr : null
    }
}
