import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(() => {
    // const { initialize } = useGtag()
    const domainStore = useDomainStore()

    if (domainStore.gtagIsEnabled) {
        // initialize('G-E8VV9RRZNF')
        // initialize(domainStore.measurementIds[0])
        // domainStore.measurementIds.forEach((id: string) => {
        //     console.log(id)
        //     initialize(id)
        // })
    }
})
