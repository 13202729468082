<template>
    <NuxtLink
        :href="href ? localePath(href) : undefined"
        :target="target"
        :rel="rel"
        :no-rel="noRel"
        :external="routeIsCurrentRoute"
    >
        <slot />
    </NuxtLink>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const localePath = useLocalePath()
const route = useRoute()

const props = defineProps({
    href: {
        type: [String, undefined, null] as PropType<string | undefined | null>,
        required: true,
    },
    target: {
        type: [String, undefined] as PropType<string | undefined>,
        default: undefined,
    },
    rel: {
        type: [String, undefined] as PropType<string | undefined>,
        default: undefined,
    },
    noRel: {
        type: [Boolean, undefined] as PropType<boolean | undefined>,
        default: undefined,
    },
})

const itemUrlWithoutQuery = computed(() => props.href?.split('?')[0])
const routeIsCurrentRoute = computed(
    () => route.path === itemUrlWithoutQuery.value,
)
</script>
